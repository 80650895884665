import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { Avatar } from '@naturacosmeticos/natds-web/dist/Components/Avatar';
import { useStoreState } from 'easy-peasy';
import FlexBox from '../../components/flex-box/FlexBox';
import Icon from '../../components/icon/Icon';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import Button from '../../components/button/Button';
import { I18nContext } from '../../config/i18n';
import DualText from '../../components/dual-text/DualText';
import LinkComponent from '../../components/link-component/LinkComponent';
import { handlePhoneContries } from '../../utils/numbers-utils';
import config from '../../config/config';

const digitalSpaceBaseUrl = process.env.REACT_APP_DIGITAL_SPACE_BASE_URL;
const handlePhone = handlePhoneContries[config.general.country];

function DigitalSpace() {
  const theme = useTheme();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const history = useHistory();
  const {
    imageCN,
    nameTent,
    personEmail,
    introMessage,
    whatsWappNumber,
    personDigitalSpaceName,
  } = useStoreState((state) => state.main);

  const linkDigitalSpace = personDigitalSpaceName ? `${digitalSpaceBaseUrl}${personDigitalSpaceName}` : false;

  function handleEditDigitalSpace() {
    history.push('/digital-space');
  }
  function openInstagram() {
    console.log('aqui vai abrir o instagram');
  }
  function openWhats() {
    console.log('aqui vai abrir o whatsapp');
  }
  function openDigitalSpace() {
    window.open(linkDigitalSpace);
  }

  return (
    <Card>
      <FlexBox m="0 0 10px 0" justifyContent="space-between" alignItems="center" flexDirection={isMobile && 'column'}>
        <FlexBox>
          <Typography variant="h5" color="primary">{translate('profile.digital_space_title')}</Typography>
        </FlexBox>
        <FlexBox
          alignItems="center"
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          flex="unset"
          margin={isMobile && '5px 0px 0px 0px'}
          gap="10px"
        >
          <Button
            variant={isMobile ? 'outlined' : 'text'}
            color="primary"
            onClick={openDigitalSpace}
            style={isMobile ? { width: '100%' } : {}}
          >
            {translate('profile.button.digital_space')}
          </Button>
          <Button
            variant={isMobile ? 'outlined' : 'text'}
            color="primary"
            onClick={handleEditDigitalSpace}
            style={isMobile ? { width: '100%' } : {}}
          >
            {translate('profile.button.edit_digital_space')}
          </Button>
        </FlexBox>
      </FlexBox>
      <FlexBox
        p={isMobile ? '0px 0 20px 0' : '10px 0 20px 0'}
        borderBottom={`1px solid ${theme.color.lowEmphasis}`}
        alignItems="center"
      >
        <Avatar
          alt="Nat Natura holding a avatar"
          color="primary"
          size="large"
          src={imageCN}
        />
        <Typography variant="h5" m="0 0 0 10px">
          {nameTent}
        </Typography>
      </FlexBox>
      <FlexBox p="15px 0" flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          {linkDigitalSpace && (
          <FlexBox flexDirection="column">
            <Typography
              variant="h6"
              m="5px 0"
              color="textSecondary"
            >
              {translate('profile.digital_space.share_title')}
            </Typography>
            <LinkComponent
              color="default"
              underline="none"
              variant="body2"
              href={linkDigitalSpace}
              target="_blank"
              style={isMobile ? {
                whiteSpace: 'nowrap',
                width: '-webkit-fill-available',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              } : {}}
            >
              {linkDigitalSpace}
            </LinkComponent>
          </FlexBox>
          )}
          <Typography
            variant="h6"
            m="15px 0 10px 0"
            color="textSecondary"
          >
            {translate('profile.digital_space.welcome_msg_title')}

          </Typography>
          <Typography>
            {introMessage}
          </Typography>
        </FlexBox>
        <FlexBox flexDirection="column">
          {false && (
          <FlexBox m="0 0 30px 0" flexDirection="column">
            <Typography
              variant="h6"
              color="textSecondary"
            >
              {translate('profile.digital_space.social_media')}
            </Typography>
            <FlexBox>
              <Button onClick={openWhats} minWidth="unset" p="0 5px" variant="text">
                <Icon
                  name="filled-brand-whatsapp"
                  size="small"
                />
              </Button>
              <Button onClick={openInstagram} minWidth="unset" p="0 5px" variant="text">
                <Icon
                  name="filled-brand-instagram"
                  size="small"
                />
              </Button>
            </FlexBox>
          </FlexBox>
          )}
          <FlexBox flexDirection="column">
            <Typography variant="h6" color="textSecondary">{translate('profile.digital_space.contact')}</Typography>
            <DualText text1="WhatsApp:" text2={handlePhone.normalize(whatsWappNumber)} />
            <DualText text1={translate('profile.email')} text2={personEmail} />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default DigitalSpace;
