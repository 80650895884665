import { action, computed, thunk } from 'easy-peasy';
import { sameMonth, betweenDates } from '../../utils/date-utils';
import { getAuthTokenPanelGlobal } from '../../utils/encrypt-utils';
import { toUpperCaseTheFirstLetter } from '../../utils/strings-utils';
import panelGlobalBffFetcher from '../../api/panelGlobalBffFetcher';

const initialState = {
  customers: [],
  originalData: [],
  customer: {},
  loading: false,
  error: false,
  totalWithFiltersCustomers: 0,
  totalCustomers: 0,
  filter: {
    birthday: false,
    search: null,
    period: null,
    // mfe filters
    selectedBirthday: [],
    selectedStates: [],
    periodPurchase: null,
    letter: null,
  },
  page: 1,
  activeLetter: 'TODOS',
  searchName: '',
  sortOrder: 'asc',
  filterBirthdateMonth: null,
  filterGender: null,
};

const customersModel = {
  ...initialState,
  resetStoreCustomers: action((state) => {
    Object.assign(state, initialState);
  }),
  addCustomers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.customers = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),

  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),

  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),

  customersCount: computed((state) => state.customers.length),

  setFilter: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filter = payload;
  }),

  // padrão mfe
  setTotalCustomers: action((state, payload) => {
  // eslint-disable-next-line no-param-reassign
    state.totalCustomers = payload;
  }),
  setTotalWithFiltersCustomers: action((state, payload) => {
  // eslint-disable-next-line no-param-reassign
    state.totalWithFiltersCustomers = payload;
  }),
  setPage: action((state, payload) => {
  // eslint-disable-next-line no-param-reassign
    state.page = payload;
  }),
  setSearchName: action((state, payload) => {
  // eslint-disable-next-line no-param-reassign
    state.searchName = payload;
  }),
  setCurrentInitialPage: action((state, payload) => {
  // eslint-disable-next-line no-param-reassign
    state.currentInitialPage = payload;
  }),
  setSortOrder: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.sortOrder = payload;
  }),
  setTotalPages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.totalPages = payload;
  }),
  setActiveLetter: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.activeLetter = payload;
  }),
  setFilterBirthdateMonth: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterBirthdateMonth = payload;
  }),
  setFilterGender: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterGender = payload;
  }),
  filterBirthdaysByPeriod: action((state, payload) => {
    if (payload) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.originalData.filter((item) => betweenDates(
        new Date(item.birthDate),
        payload.initialDate,
        payload.finalDate,
      ));
    } else {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.originalData;
    }
  }),

  // eslint-disable-next-line no-unused-vars
  /*   fetchCustomers: thunk(async (actions, payload) => {
    const { personId } = payload;
    actions.setLoading(true);
    actions.setError(false);
    fetcher().get(`customers?personId=${personId}`, {
      headers: {
        personId,
        cnId: generateHash(personId),
      },
    }).json()
      .then((data) => {
        actions.addOriginalData(data.customer);
        actions.addCustomers(data.customer);
      })
      .catch((err) => {
        actions.setError(true);
        console.error('error: -> ', err.message);
      });
  }), */
  fetchCustomersBff: thunk(
    async (
      actions,
      {
        page,
        activeLetter,
        searchName,
        birthdateMonth,
        filterGender,
      },
    ) => {
      const authToken = localStorage.getItem('authToken');
      actions.setLoading(true);
      actions.setError(false);
      const getNameFilter = searchName
        ? `filter=${toUpperCaseTheFirstLetter(searchName)}&`
        : '';
      const getLetterFilter = activeLetter === 'TODOS' ? '' : `letterFilter=${activeLetter}`;
      const getBirthMonth = birthdateMonth
        ? `&birthFilter=${birthdateMonth}`
        : '';
      const getFilterGender = filterGender ? `&gender=${filterGender}` : '';
      if (!getNameFilter) {
        actions.setSearchName('');
      }
      panelGlobalBffFetcher()
        .get(
          `customers/page/${page}?`, {
            headers: {
              Authorization: getAuthTokenPanelGlobal(authToken),
            },
          },
        )
        .json()
        .then((data) => {
          actions.setPage(page + 1);
          actions.addOriginalData(data.customers);
          actions.addCustomers(data.customers);
          actions.setTotalWithFiltersCustomers(data.totalCustomers);
          actions.setTotalPages(data.totalPages || 1);
          if (
            !getLetterFilter && !getNameFilter && !getBirthMonth && !getFilterGender
          ) {
            actions.setTotalCustomers(data.totalCustomers);
          }
        })
        .catch((err) => {
          actions.setError(true);
          console.error('error: -> ', err.message);
        });
    },
  ),
  filterCustomers: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.customers = state.originalData;
    const { search, birthday, period } = state.filter;
    if (search) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter((item) => {
        if (!item.fullName) return false;
        return (item.fullName.toUpperCase().indexOf(search.toUpperCase()) > -1);
      });
    }
    if (birthday) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter((item) => sameMonth(item.birthDate, new Date()));
    }
    if (period) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter(
        (item) => betweenDates(new Date(item.birthDate), period.initialDate, period.finalDate),
      );
    }
  }),
};

export default customersModel;
