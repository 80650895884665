const loginConfig = {
  natura_login: {
    loginUrl: `${process.env.REACT_APP_AUTH_SSO_URL}`
  + `&callback=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`,
    logoutUrl: `${process.env.REACT_APP_AUTH_SSO_URL}/sign-out`
  + `&callback=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`,
    callbackParam: `#${process.env.REACT_APP_AUTH_SSO_CALLBACK_PARAM}?`,
  },
  cognito_login: {
    loginUrl: `${process.env.REACT_APP_AUTH_SSO_URL}`
  + `?client_id=${process.env.REACT_APP_AUTH_SSO_CLIENT_ID}`
  + `&country=${process.env.REACT_APP_COGNITO_COUNTRY || process.env.REACT_APP_COUNTRY}`
  + `&language=${process.env.REACT_APP_LANGUAGE}`
  + `&company=${process.env.REACT_APP_COMPANY}`
  + `&redirect_uri=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`,
    logoutUrl: `${process.env.REACT_APP_AUTH_SSO_URL}/sign-out`
  + `?client_id=${process.env.REACT_APP_AUTH_SSO_CLIENT_ID}`
  + `&country=${process.env.REACT_APP_COGNITO_COUNTRY || process.env.REACT_APP_COUNTRY}`
  + `&language=${process.env.REACT_APP_LANGUAGE}`
  + `&company=${process.env.REACT_APP_COMPANY}`
  + `&redirect_uri=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`,
    callbackParam: `?${process.env.REACT_APP_AUTH_SSO_CALLBACK_PARAM}=`,
  },
};
const loginUrl = `${process.env.REACT_APP_AUTH_SSO_URL}`
  + `?client_id=${process.env.REACT_APP_AUTH_SSO_CLIENT_ID}`
  + `&country=${process.env.REACT_APP_COGNITO_COUNTRY || process.env.REACT_APP_COUNTRY}`
  + `&language=${process.env.REACT_APP_LANGUAGE}`
  + `&company=${process.env.REACT_APP_COMPANY}`
  + `&redirect_uri=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`;

const logoutUrl = `${process.env.REACT_APP_AUTH_SSO_URL}/sign-out`
  + `?client_id=${process.env.REACT_APP_AUTH_SSO_CLIENT_ID}`
  + `&country=${process.env.REACT_APP_COGNITO_COUNTRY || process.env.REACT_APP_COUNTRY}`
  + `&language=${process.env.REACT_APP_LANGUAGE}`
  + `&company=${process.env.REACT_APP_COMPANY}`
  + `&redirect_uri=${process.env.REACT_APP_AUTH_SSO_REDIRECT_URL}`;
const config = {
  api: {
    url: process.env.REACT_APP_BACKEND_URL,
    visitUrl: process.env.REACT_APP_BACKEND_VISITS_URL,
    urlNode: process.env.REACT_APP_PANEL_GLOBAL_BFF_URL,
    naturaAuthUrl: process.env.REACT_APP_NATURA_AUTH_URL,
    mockCnData: process.env.REACT_APP_BACKEND_URL_CN_DATA,
    clientId: process.env.REACT_APP_BACKEND_CLIENT_ID,
    clientSecret: process.env.REACT_APP_BACKEND_CLIENT_SECRET,
    clientIdAuth: process.env.REACT_APP_BACKEND_CLIENT_ID_AUTH,
    clientSecretAuth: process.env.REACT_APP_BACKEND_CLIENT_SECRET_AUTH,
    // url: process.env.NODE_ENV === 'development' ? '/api' : process.env.REACT_APP_BACKEND_URL,
    // url: '/api',
    /* mfeAuthUrl: process.env.REACT_APP_MFE_AUTH_URL,  */
  },
  apiTag: {
    tagUrl: process.env.REACT_APP_TAG_USER_ACTION_URL,
    tagAuthToken: process.env.REACT_APP_TAG_AUTH_TOKEN,
  },
  bffApi: {
    url: process.env.REACT_APP_BFF_URL,
    clientId: process.env.REACT_APP_BFF_CLIENT_ID,
    clientSecret: process.env.REACT_APP_BFF_CLIENT_SECRET,
    tenantId: process.env.REACT_APP_BFF_TENANT_ID,
    registerPage: process.env.REACT_APP_BFF_REGISTER_PAGE,
  },
  catalogApi: {
    url: process.env.REACT_APP_CATALOG_API_URL,
    key: process.env.REACT_APP_CATALOG_API_KEY,
  },
  auth: {
    loginUrl: loginConfig[process.env.REACT_APP_AUTH_SSO_CONFIG]?.loginUrl || loginUrl,
    logoutUrl: loginConfig[process.env.REACT_APP_AUTH_SSO_CONFIG]?.logoutUrl || logoutUrl,
    loginConfig: process.env.REACT_APP_AUTH_SSO_CONFIG,
    redirectUrl: process.env.REACT_APP_AUTH_SSO_REDIRECT_URL,
    callbackParam:
      loginConfig[process.env.REACT_APP_AUTH_SSO_CONFIG]?.callbackParam
      || process.env.REACT_APP_AUTH_SSO_CALLBACK_PARAM,
    authHeader: process.env.REACT_APP_AUTH_SSO_CALLBACK_PARAM,
  },
  general: {
    company: process.env.REACT_APP_COMPANY,
    brand: process.env.REACT_APP_BRAND,
    country: process.env.REACT_APP_COUNTRY,
    language: process.env.REACT_APP_LANGUAGE,
    languageIso: process.env.REACT_APP_LANGUAGE_ISO,
    currencyIso: process.env.REACT_APP_CURRENCY_ISO,
    currencyFractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,
    taxSales: process.env.REACT_APP_TAX_SALE === 'true',
    customerStateDisplay: process.env.REACT_APP_CUSTOMER_STATE_DISPLAY === 'true',
    storeReactivation: process.env.REACT_APP_STORE_REACTIVATION,
    acessStoreLink: process.env.REACT_APP_ACCESS_STORE_LINK,
    minimunDiscountCoupons: process.env.REACT_APP_MINIMUN_DISCOUNT_COUPONS,
    maximunDiscountCoupons: process.env.REACT_APP_MAXIMUN_DISCOUNT_COUPONS,
    maximunWithoutLevelCoupons: process.env.REACT_APP_MAXIMUN_WITHOUT_LEVEL_COUPONS,
    loginType: process.env.REACT_APP_AUTH_SSO_CONFIG,
    // eslint-disable-next-line max-len
    levelsDiscountCoupons: process.env.REACT_APP_LEVELS_COUPONS_DISCOUNT && JSON.parse(process.env.REACT_APP_LEVELS_COUPONS_DISCOUNT),
    stepCoupons: process.env.REACT_APP_STEP_COUPONS,
    DDIOfContry: process.env.REACT_APP_DDI_COUNTRY,
    franchiseAgreementDocumentLink: process.env.REACT_APP_FRANCHISE_AGREEMENT_DOCUMENT_LINK,
    termsOfUserDocumentLink: process.env.REACT_APP_TERMS_OF_USER_DOCUMENT_LINK,
    mercadoPagoDocumentLink: process.env.REACT_APP_MERCADO_PAGO_DOCUMENT_LINK,
    googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    searchZipCodeUrl: process.env.REACT_APP_SEARCH_ZIP_CODE_URL,
    maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE,
    changePasswordLink: process.env.REACT_APP_CHANGE_PASSWORD_LINK,
    maxWidthOrHeightToCompressImage: process.env.REACT_APP_MAX_WIDTH_OR_HEIGHT_TO_COMPRESS_IMAGE,
    secretCrypto: process.env.REACT_APP_SECRET_CRYPTO,
    reactivationMode: process.env.REACT_APP_STORE_REACTIVATION_MODE,
    mainPersonIdParam: process.env.REACT_APP_MAIN_PERSONID_PARAM,
    useGetDataInHome: process.env.REACT_APP_USE_GET_DATA_IN_HOME,
    showMpInHome: process.env.REACT_APP_SHOW_MP_IN_HOME,
    showCatalog: process.env.REACT_APP_SHOW_CATALOG,
    showHome: process.env.REACT_APP_SHOW_HOME === 'true',
    hardCodedCustomerId: process.env.REACT_APP_HARD_CODED_CUSTOMER_ID,
    hardCodedCustomerNo: process.env.REACT_APP_HARD_CODED_CUSTOMER_NO,
  },
};

export default config;
